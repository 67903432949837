import "./StationOptions.css";
import { NavLink, useParams } from "react-router-dom";
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import React from "react";

export default function StationOptions(props) {
  const { stationId, studentId } = useParams();
  const { auth, setAuth } = useAuth();
  const { getAssignments, navbarAssignments } = props;

  useEffect(() => {
    try {
      if (auth?.userType === "Mentor") {
        if (
          !(
            navbarAssignments &&
            navbarAssignments.length > 0 &&
            stationId &&
            navbarAssignments[0]?.station === stationId
          )
        ) {
          getAssignments(stationId);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
        localStorage.removeItem("user");
        setAuth({});
      }
    }
  }, [stationId]);

  return (
    <div className="station-assignments-list-container">
      <div className="station-assignment-list-image">
        <img src="/images/undraw_learning_re_32qv.svg" alt="teaching.svg" />
      </div>
      <div className="station-assignments-list">
        <div className="station-assignment-container">
          <NavLink
            to={
              auth?.userType === "Student"
                ? props.station + "/announcements"
                : "announcements"
            }
            style={{ textDecoration: "none" }}
          >
            <div className="station-assignment">
              <img
                className="station-assignment-image-announce"
                src="/images/announcement.png"
                alt="assignment"
              />
              <div className="station-assignment-name">Announcements</div>
              <div className="arrow"></div>
            </div>
          </NavLink>
        </div>
        <div className="station-assignment-container">
          <NavLink
            to={
              auth?.userType === "Student" ? props.station + "/diary" : "diary"
            }
            style={{
              textDecoration: "none",
            }}
          >
            <div className="station-assignment">
              <img
                className="station-assignment-image"
                src="/images/assignment (1).png"
                alt="assignment"
              />
              <div className="station-assignment-name">Weekly Diary</div>
              <div className="arrow"></div>
            </div>
          </NavLink>
        </div>
        <div className="station-assignment-container">
          <NavLink
            to={"statistics"}
            style={{
              textDecoration: "none",
              display: auth?.userType !== "Student" ? "block" : "none",
            }}
          >
            <div className="station-assignment">
              <img
                className="station-assignment-image-announce"
                src="/images/statistics.png"
                alt="assignment"
              />
              <div className="station-assignment-name">Statistics</div>
              <div className="arrow"></div>
            </div>
          </NavLink>
        </div>

        {/* uncomment this when the scores page is needed */}
        {/* <div className="station-assignment-container">
          <NavLink
            to={"scores"}
            style={{
              textDecoration: "none",
              display: auth?.userType !== "Student" ? "block" : "none",
            }}
          >
            <div className="station-assignment">
              <img
                className="station-assignment-image-announce"
                src="/images/score.png"
                alt="assignment"
              />
              <div className="station-assignment-name">Scores</div>
              <div className="arrow"></div>
            </div>
          </NavLink>
        </div> */}


        {/* <div className="station-assignment-container">
          <NavLink
            to={"quiz"}
            style={{
              textDecoration: "none",
              display: auth?.userType !== "Student" ? "block" : "none",
            }}
          >
            <div className="station-assignment">
              <img
                className="station-assignment-image-announce"
                src="/images/quiz.png"
                alt="assignment"
              />
              <div className="station-assignment-name">Quiz</div>
              <div className="arrow"></div>
            </div>
          </NavLink>
        </div> */}
        {/* <NavLink
            to={
              auth?.userType === "Student"
                ? props.station + "/report"
                : "report"
            }
            style={{ textDecoration: "none" }}
          >
            <div className="station-assignment">
              <img
                className="station-assignment-image-announce"
                src="/images/quiz.png"
                alt="assignment"
              />
              <div className="station-assignment-name">Report</div>
              <div className="arrow"></div>
            </div>
          </NavLink> */}
      </div>
    </div>
  );
}
